<template>
  <div class="article-detail">
    <h2 class="article-title">{{ dataSource.articleTitle }}</h2>
    <!--    <p class="article-description">{{ dataSource.description }}</p>-->
    <div class="article-meta">
      <!--      <span class="article-category">{{ dataSource.categoryName }}</span>-->
      <span class="article-date">最后一次修改时间: {{ dataSource.updateTime }}</span>
    </div>
    <el-divider/>
    <div class="article-content" v-html="dataSource.articleContent"></div>
  </div>
</template>

<script>
import {findArticleDetailByFlag} from '@/api/home'
import {reactive, ref, watch} from 'vue'
import {useRoute} from 'vue-router'

export default {
  name: 'HomeBanner',
  components: {},
  setup() {
    const route = useRoute()
    const dataSource = ref([])
    let aa = route.params.flag
    const time = ref()
    findArticleDetailByFlag(aa).then(data => {
      dataSource.value = data.data
      //获得当前时间
      time.value = data.time

    })
    // 监听路由变化
    watch(() => route.params, (newParams) => {
      const time = ref()
      findArticleDetailByFlag(newParams.flag).then(data => {
        dataSource.value = data.data
        //获得当前时间
        time.value = data.time
      })
    })
    return {dataSource}
  }
}

</script>
<style scoped lang="less">
.article-detail {
  width: 85%;
  background: #fff;
  padding-left: 30px;
  padding-right: 30px;
}

.article-title {
  font-size: 32px;
  margin-bottom: 10px;
}

.article-description {
  margin-bottom: 20px;
  background-color: #f2f2f2;
  padding: 10px;
}

.article-meta {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.article-category {
  background-color: #f2f2f2;
  padding: 5px 10px;
  border-radius: 5px;
}

.article-date {
  color: #888;
}

.article-content {
  margin-top: 20px;
  line-height: 1.5;
}

</style>
